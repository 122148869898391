.deactivate-user-modal {
  width: 360px;
  height: 400px;
  padding: 22px 24px;

  .modal__header--empty {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    font-size: 16px;
    font-weight: 700;
    border: 0;
    padding: 0;
    margin: 0;
  }

  .close-btn {
    background: none;
    border: 0;
    cursor: pointer;
  }

  .content {
    margin-top: 54px;
  }

  .user {
    display: flex;
    align-items: center;
    margin-left: 8px;

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .role {
        padding: 6px 8px;
        background: rgba(#e1e1ea, 0.2);
        border-radius: 16px;
        &-title {
          margin: 0;
          font-size: 12px;
          color: var(--fill-grey-medium);
        }
      }
    }

    &-image {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    &-name {
      margin: 0;
      margin-left: 10px;
      color: var(--fill-grey-dark);
      text-decoration: line-through;
    }
  }

  .subtitle {
    margin-top: 32px;
    font-size: 14px;
    color: var(--fill-grey-dark);

    &.error {
      color: var(--fill-orangered);
    }
  }

  .footer {
    height: 56px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
