$profile-cover-size: 175px;

.profile-settings {
  flex-grow: 1;

  padding: 20px 24px;

  > h1 {
    margin: 20px 0;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .content {
    display: flex;

    .img {
      max-width: 100%;
      position: relative;
      height: $profile-cover-size;
      width: $profile-cover-size;
      border-radius: 4px;
      overflow: hidden;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border: 1px solid var(--fill-grey-light2);
      padding: 5px;

      &.img-invalid {
        background-color: #6f4ce6;
      }

      img {
        object-fit: cover;
        width: $profile-cover-size;
        max-height: $profile-cover-size;
      }

      .name-initials {
        font-size: 32px;
        color: white;
        line-height: $profile-cover-size;
        text-align: center;
      }
      & + .actions {
        margin-top: 10px;
        text-align: right;
        > .action {
          display: inline-block;
          & + .action {
            &:before {
              content: "\2024";
              color: var(--fill-selection-dark);
              margin: 0 5px;
              vertical-align: 2px;
            }
          }
        }
      }
    }

    .profile-fields-container {
      max-width: 356px;
      margin-right: 56px;
    }
  }

  .tabular-fields {
    font-size: 14px;
    line-height: 20px;
    flex-direction: column;

    // * {
    //   outline: 1px solid orange;
    // }

    .field-row {
      margin-top: 20px;
      min-height: 44px;
      display: flex;
      flex-direction: row;
      align-items: center;

      > .field-label {
        color: var(--fill-grey-medium);
        min-width: 100px;
        margin: 12px 0;
      }

      > .field-value {
        margin-left: 32px;
        flex-grow: 1;
      }
    }
  }

  .link {
    text-transform: unset;
    color: var(--fill-selection-dark);
    font-size: inherit;
    cursor: pointer;
  }
}

.update-user-phone-modal {
  min-width: 400px;
  max-width: 400px;
  > .container {
    border: none;
    margin: 16px;
  }
}
