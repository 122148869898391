.signup__wrapper {
    // margin: auto;
    // max-width: 50vw;
    // display: inline-block;
}

.signup__title {}

.signup__input-grp {
    width: 100%;

    label,
    input {
        display: block;
        text-align: left;
    }

    input {
        width: 100%;
    }

}

.signup__toc {
    label {
        margin-left: 1em;
        user-select: none;
        cursor: pointer;
    }
}