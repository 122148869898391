.radio_button {
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 24px;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  height: 40px;
  line-height: 20px;
  margin-right: 16px;
  padding: 10px 16px;
  background-color: white;
  color: black;
  padding-left: 20px;
}

.element {
    padding: 10px;
    background-color: white ;
    color: black ;
}
.basicInfocontainer {
    padding : 10px;
    border-style: 1px solid black;
}
.basicHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  flex-direction: row;
}
.inputFormik {
    height: 40px;
    background-color: white ;
    color: black ;
}

.inputFormikClear {
    padding: 10px;
    background-color: white ;
    color: black;
}

.errorMsg {
  color: red;
}
.customerLabel{
  padding-right: 20px;
}

.phone-input{
  .form-control {
    width: 100%;

    &:focus + .flag-dropdown {
        border: 1px solid var(--fill-primary-blue);
        border-right: none;
      }
  }
}