$standard-colors: (
  "green": var(--fill-success),
  "red": var(--fill-orangered),
  "white": white,
  "black": var(--fill-locofast-black),
  "blue": var(--fill-primary-blue),
  "grey-medium": var(--fill-grey-medium),
  "yellow": var(--fill-yellow),
);

@mixin fill-colors($prop-color: "fill", $prop-element: "path") {
  #{$prop-element} {
    #{$prop-color}: var(--fill-stroke-color);
  }
}

.loco-icon {
  --fill-stroke-color: var(--fill-locofast-black);
  pointer-events: none;

  @each $name, $color in $standard-colors {
    &.loco-icon--color-#{$name} {
      --fill-stroke-color: #{$color};
    }
  }

  &--info {
    @include fill-colors("fill");
    width: 100%;
  }

  &--arrow {
    @include fill-colors("stroke");

    &.loco-icon {
      &--dir {
        // default is down

        &-left {
          transform: rotate(90deg);
        }
        &-up {
          transform: rotate(180deg);
        }
        &-right {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &--check-mark {
    @include fill-colors("stroke");
  }

  &--close {
    @include fill-colors("stroke");
  }

  &--location {
    @include fill-colors;
  }

  &--factory-machine {
    @include fill-colors;
  }

  &--star {
    @include fill-colors;
  }

  &--save {
    @include fill-colors("stroke");
  }

  &--submit {
    @include fill-colors("stroke");
  }

  &--plus {
    height: 16px;
    width: 16px;
    @include fill-colors("stroke");
  }

  &--email {
    @include fill-colors("stroke");
  }

  &--phone {
    @include fill-colors("stroke");
  }

  &--help {
    @include fill-colors("stroke");
    @include fill-colors("fill", "circle");
  }

  &--filter {
    @include fill-colors("fill");
  }

  &--magnifier {
    @include fill-colors("stroke");
  }

  &--calendar {
    @include fill-colors("fill");
  }

  &--edit-pencil {
    @include fill-colors("fill");
  }

  &--arrow-broad {
    @include fill-colors("fill");
  }

  &--arrow-long {
    @include fill-colors("stroke");
  }

  &--attach-file {
    @include fill-colors("fill");
  }

  &--arrow-drop-down {
    @include fill-colors("fill");
  }

  &--copy {
    @include fill-colors("fill");
  }

  // &--expand-more {
  //   @include fill-colors("fill");
  // }

  &--add-avatar {
    @include fill-colors("fill");
    @include fill-colors("stroke", "circle");
  }

  &--unlink {
    @include fill-colors("fill");
  }

  &--arrow-chevron {
    @include fill-colors("fill");
  }

  & + .icon-text, .icon-text + & {
    margin-left: 4px;
    vertical-align: top;
  }
}
