.view-team-modal {
  width: 328px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);

  .modal__header {
    font-weight: 600;
    font-size: 16px;
    margin: 24px 16px 16px;

    .count {
      background: rgba(225, 225, 234, 0.2);
      padding: 4px 8px;
      border-radius: 50%;
      font-size: 12px;
      margin-left: 8px;
    }
  }

  .modal__close img{
    opacity: 0.7;
  }

  .team-content {
    padding: 8px;

    &__container {
      max-height: 264px;
      overflow: auto;
      padding-bottom: 14px;
    }

    &__item {
      padding: 8px;
      margin-bottom: 8px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      div:first-child {
        display: flex;
        align-items: center;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .profile-avatar {
        box-shadow: none;
      }

      &_name {
        padding-left: 16px;
      }

      &_role {
        color: var(--fill-grey-medium);
        font-size: 12px;
        font-weight: 600;
        background-color: rgba(225, 225, 234, 0.2);
        padding: 6px 8px;
        border-radius: 16px;
        line-height: 16px;
      }
    }

    &__manage-team {

      display: flex;
      justify-content: center;
      border-top: 1px solid var(--fill-grey-light3);

      button {
        color: var(--fill-grey-medium);
        border: 0;
        padding-top: 16px;
        font-weight: normal;
      }
    }
  }
}
