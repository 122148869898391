.upload-progress__container {
  position: absolute;
  bottom: 6em;
  right: 6em;
  padding: 10px 20px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px var(--fill-grey-light2); 
  background: var(--fill-white);
  display: flex;
  align-items: center;

  div {
    padding: 5px;
  }
}

