.settings-mills {
  position: relative;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;

  .millsPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  
  //TODO -- It should to be removed, once we upgrade the antd liabrary.
  .ant-page-header-heading-extra {
    right: 70px;
  }

}

.activeOrders {
  margin-left: 20px;
}