.input-grp-radio > .flex {
  > label {
    flex: 1;
    width: unset;
    font-size: 14px;
    cursor: default;
  }

  .display-value {
    vertical-align: 3px;
    font-size: 14px;
    margin-left: 10px;
  }

  .input-container {
    display: flex;
    .label-radio {
      white-space: nowrap;

      .fake-radio {
        position: relative;
        display: inline-block;
        border: 1px solid var(--fill-grey-medium);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        &::before {
          content: " ";
          position: absolute;
          background-color: transparent;
          transition: all 100ms;
          width: 0;
          height: 0;
          left: 7px;
          top: 7px;
        }
      }

      input {
        display: none;

        &:checked {
          & + .display-value-container .fake-radio {
            border-color: var(--fill-selection-dark);

            &::before {
              background-color: var(--fill-selection-dark);
              border-radius: 50%;
              left: 4px;
              top: 4px;
              width: 8px;
              height: 8px;
            }
          }
        }

        &:disabled {
          &:checked {
            & + .display-value-container .fake-radio {
              border-color: var(--fill-grey-medium);

              &::before {
                background-color: var(--fill-grey-medium3);
              }
            }
          }
        }

        & + .display-value {
          color: blue;
        }
      }

      & + .label-radio {
        margin-left: 28px;
      }
    }
  }
}
