.custom-login {
  padding: 24px 32px 24px;
  border: 1px solid var(--fill-grey-light2);
  border-radius: 8px;
  max-width: 412px;
  min-width: 412px;

  h1 {
    margin: 0.8em 30px 1.6em;
    font-size: 24px;
    text-align: center;
  }

  header {
    text-align: center;
    padding: 8px 0;
    img {
      max-width: 100px;
      max-height: 30px;
      object-position: center;
      object-fit: contain;
    }

    // .lf-logo {
    //   path {
    //     &:nth-child(2) {
    //       fill: var(--fill-primary-dark);
    //     }
    //   }
    // }
  }

  footer {
    display: flex;
    color: var(--fill-grey-medium);
    padding: 12px;
    justify-content: center;
    align-items: center;
    padding-top: 42px;
    padding-bottom: 0;
    img {
      padding: 0 8px;
    }
  }
}

.login__forgot-password {
  color: var(--fill-primary-medium);
  margin-bottom: 2em;
}

.login__signup-message {
  margin-top: 3em;
  align-items: center;
  justify-content: space-between;
}
