.auth {
    height: 100%;
}

.auth__main {
    height: 100%;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.auth__right {
    &>* {
        width: 30vw;
    }
}