@import "../../../assets/scss/colors.scss";

.page {
  margin: 40px 32px 0 32px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.runningQuality-drawer {
  height: 100vh;

  .drawer_footer {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .data_area {
    margin-left: 10%;
  }
  
}

.ant-drawer {
  .ant-drawer-content-wrapper {
    height: 300px !important;
  }

}

.ant-drawer-content {
  height: 400px;
}

.ant-drawer-open {
  .ant-drawer-mask {
    height: 100vh;
  }
}

.ant-drawer-mask {
    height: 100vh !important;
}

.select-item {
    height: 56px;
    width: 48%;
}

.formField {
  width: 48%;

  button {
    width: 100%;
    border-radius: 8px;
  }
}

.downloadAnchor {
  text-decoration: underline;
  color: var(--fill-primary-blue)
}

.labelWithTick {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.footerCtaContainer {
  margin-top: 58px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-row {
  .form-column {
    margin-bottom: 20px;
  }
  .form-button {
    display: flex;
    justify-content: space-around;
    margin-top: 2.5rem;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headline {
    font-weight: 700;
    font-size: 20px;
    line-height: 27.28px;
  }
}

.table-count {
  position: absolute;
  margin-top: 20px;
}