.int-sidebar.int-sidebar--notification {
  max-width: 300px !important;
  min-width: 300px !important;
  // box-shadow: 2px 0 6px 0 rgba(0, 35, 38, 0.25);

  background-color: #0277bd;

  .int-sidebar__portion:first-child {
    // border-bottom: 0;
    box-shadow: inset 0 -1px 0 0 var(--fill-grey-light2);
  }
}
.int-sidebar {
  max-width: 250px;
  flex-basis: 20%;
  min-width: 20%;
  // min-width: 215px;
  border-right: 1px solid var(--fill-grey-light2);
  background-color: #ffffff;
  flex-direction: column;
  z-index: 2;
  height: 100%;
  :first-child {
    border-top: 0;
  }
}

.int-sidebar__portion {
  border-bottom: 1px solid var(--fill-grey-light3);
  border-top: 1px solid var(--fill-grey-light3);
  align-items: center;
}

.int-sidebar__list {
  flex-grow: 1;
  overflow: auto;
}
