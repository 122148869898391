.element {
  padding: 10px;
}
.basicInfocontainer {
  padding: 10px;
  border-style: 1px solid black;
}
.ant-modal {
  .ant-row {
    row-gap: 0px !important;
  }
}
.basicHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  flex-direction: row;
}
.inputFormikClear {
  padding: 10px;
  background-color: white;
  color: black;
}
.advanceRequiredInput {
  height: 42px;
}
.turnover {
  border: 1px solid var(--fill-grey-light);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.paymentTermsInput {
  width: 103%;
}
.payment-label {
  padding: 10px;
}
.creditPeriod {
  border-radius: 0.25rem;
  width: 200px;
  border: 1px solid var(--fill-grey-light);
  font-size: 1rem;
  margin-right: 62px !important;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 47px !important;
}
.ant-select-selection {
  border: 1px solid #bfbfbf !important;
}
.ant-select-open .ant-select-selection {
  border: 1px solid black !important;
}
.ant-select-selection:hover,
.ant-select-selection:active {
  border-color: black !important;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  margin-top: 3px;
}
.error {
  margin-top: 3px;
  font-size: 0.8rem;
  color: red;
}

.ant-picker {
  height: 41px;
  width: 200px;
}
.docs .ant-btn {
  max-width: 633px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}