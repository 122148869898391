.create-password {
  padding: 24px 32px 32px;
  border: 1px solid var(--fill-grey-light2);
  border-radius: 8px;
  width: 412px;

  fieldset {
    border: none;
    padding: 0;

    .btn--large {
      font-weight: bold;
      line-height: 20px;
      padding: 14px 16px;
      margin-top: 20px;
    }
  }

  header {
    text-align: center;
    padding: 8px 0;
    img {
      width: 100px;
      height: 30px;
      margin-bottom: 24px;
      object-position: center;
      object-fit: contain;
    }
    .heading {
      margin-top: 16px;
      margin-bottom: 40px;
      padding: 0;
      border-bottom: none;
    }
    .h2 {
      font-size: 18px;
      line-height: 32px;
      font-weight: 600;
    }
    .h1 {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      margin-top: 4px;
    }
    + .content {
      margin-top: 24px;
    }
  }

  footer {
    display: flex;
    color: var(--fill-grey-medium);
    justify-content: center;
    align-items: center;
    padding-top: 42px;
    img {
      padding: 0 8px;
    }
  }
}
