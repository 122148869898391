.org-header {
  display: flex;
  align-items: center;
  padding: 20px 24px 16px;
  background-color: white;

  > *:not(:first-child) {
    margin-left: 16px;
  }

  &__heading {
    flex: 1;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  > .btn {
    max-height: 32px;
    padding: 6px 13px;
  }

  .select-org-type {
    min-width: 150px;
  }
}
