.user-details {
    max-height: 100%;
}

.user-details__scroll {
    max-height: 80vh;
}

.user-details__user {
    align-items: center;
    margin-bottom: 3em;
}

.user-details__img {
    border-radius: 60px;
    overflow: hidden;
    max-width: 120px;
    max-height: 120px;

    img {
        max-width: 100%;
    }

    margin-bottom: 1.2em;
}


.user-details__name {
    color: var(--fill-locofast-black);
    margin-bottom: 0.5em;
}

.user-details__email {
    color: var(--fill-grey-dark);
    letter-spacing: 0.2px;
    margin-bottom: 0.8em;
}

.user-details__joined_on {
    color: var(--fill-grey-medium);

}


.user-details__subtitle {
    color: var(--fill-grey-medium);
    margin-bottom: 1em;
}

.user-details__col-list__header {
    color: var(--fill-locofast-black);
    margin-bottom: 0.3rem;
}

.user-details__col-list__sub {
    color: var(--fill-grey-dark);
}

.user-details__col-list-left {
    max-width: 70%;

}

.user-details__col-list-right {
    margin-left: auto;

    img {
        max-height: 1.5em;
        cursor: pointer;
        user-select: none;
    }
}

.user-details__col-list {
    border-radius: 8px;
    border: 1px solid var(--fill-grey-light2);
}

.user-details__col-list>.user-details__col-list-item:last-child {
    box-shadow: none !important;
}

.user-details__col-list-item {
    padding: 1.5em 0;
    margin: 0 1em;
    box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
    line-height: 1.43;
    letter-spacing: 0.2px;

}