:root {
  --fill-secondary-dark: #fc3a52;
  --fill-secondary-medium: #fe6c8a;
  --fill-secondary-light: #ffeef2;
  --fill-primary-blue: #0067e2;
  --fill-primary-dark2: #0053bc;
  --fill-primary-dark: #004d99;
  --fill-primary-medium: #0277bd;
  --fill-primary-light: #d4efff;
  --fill-primary-lighter: #e5f1ff;
  --fill-green-dark: #02bd70;
  --fill-green-light: #bfffe4;
  --fill-locofast-black: #000126;
  --fill-grey-dark: #333340;
  --fill-grey-dark2: #626870;
  --fill-grey-dark3: #92979C;
  --fill-grey-medium: #676773;
  --fill-grey-medium2: #828282;
  --fill-grey-medium3: #979797;
  --fill-grey-light: #bfbfbf;
  --fill-grey-light2: #d4d4d4;
  --fill-grey-light3: #e5e5e5;
  --fill-grey-light4: #ededed;
  --fill-grey-light5: #f2f2f2;
  --fill-grey-light6: #f5f5f5;
  --fill-grey-light7: #f8f8f8;
  --fill-grey-light8: #e5e5e5;
  --fill-white: #ffffff;
  --fill-transparent: rgba(0, 1, 38, 0);
  --fill-error: #d32f2f;
  --fill-orangered: #eb5757;
  --fill-orange: #ffa552;
  --fill-orange-light: #fef5ed;
  --fill-blue-light: #e5f1ff;
  --fill-blue-light2: #f2f4f7;
  --fill-blue-light3: #f2f8ff;
  --fill-blue-light4: #f8fbff;
  --fill-blue-light5: #fbfdff;
  --fill-selection-light: #e5f1ff;
  --fill-selection-light2: #afd4ff;
  --fill-selection-dark: #0067e2;
  --fill-success: #27ae60;
  --fill-purple-light: #6f4ce6;
  --fill-purple-light2: #f5eefc;
  --fill-purple-light3: #a6a5c6;

  --fill-green-1: #219653;
  --fill-green-2: #27ae60;
  --fill-green-secondary: #edfef4;

  --fill-red-light1: #fdefef;
  --fill-red-light2: #fff5eb;

  --fill-green-light: #6fcf97;
  --fill-yellow-light: #fffee5;
  --fill-yellow: #ffc107;
  --fill-yellow-1: #f2994a;
  --fill-light-gray: #93939f;
  --fill-seashell: #fff7ef;
  --fill-sea-buckthorn: #f2994a;
  --fill-sky-blue: #f2f8ff;
  --fill-blue-100: #eaf4ff;
  --fill-blue-300: #90cdf4;
}

// TODO: following are old changes; if not used, please remove it
// this thing is for react dropdown to hide border
.Dropdown-control {
  border: 0px solid #fff !important;
  cursor: pointer;
  padding: 15px 10px;
}

.Dropdown-control:hover {
  border: 0px solid rgb(255, 255, 255) !important;
  box-shadow: none;
}

.Dropdown-arrow {
  top: 20px;
}
