.brand-dash {
  height: 100%;
  background-color: var(--fill-white);
}

.brand-dash__header {
  height: 8%;
  padding: 8px 10px;
  border-bottom: 1px #000000 solid;
}

.brand-dash__actions {
  margin-left: auto;
}

.brand-dash__main {
  display: flex;
  // 100% of page height - Header height
  height: calc(100% - 56px);
}

.brand-dash__content {
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  // width: 100%;
  overflow: auto;
  // overflow-x: hidden;
}

.brand-dash__content > .collections {
  overflow: auto;
  height: 100%;
}

.brand-dash__content > .custom-scroll-sample {
  position: relative;
  overflow: auto;
  height: 100%;
}
