:root {
  --btn-padding: 10px 12px;
  --fill-primary-btn-hover: var(--fill-primary-dark2);
}

.btn {
  border: 0;
  user-select: none;
  cursor: pointer;
  background: #fff;
}

.btn:focus {
  outline: 0;
}

.btn--circle {
  // Added here because other btns didn't need it.
  display: flex;
  margin: auto;
  // width: 56px;
  // height: 56px;
  background: var(--fill-primary-blue);
  border-radius: 28px !important;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  > img {
    height: 18px;
    width: 18px;
  }

  * {
    margin: auto;
    // width: 56px;
    // height: 56px;
    background: var(--fill-primary-blue);
    border-radius: 28px !important;
    color: #ffffff;

    * {
      margin: auto;
    }
  }

  &:hover {
    background-color: var(--fill-primary-btn-hover);
  }
}

.btn--circle.btn--shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn--large {
  width: 100%;
  background-color: var(--fill-primary-blue);
  text-align: center;
  color: var(--fill-white);
  padding: 1.2em 1.5em;
  border-radius: 4px;
  text-transform: uppercase;

  &:hover {
    background-color: var(--fill-primary-btn-hover);
  }
}

.btn--rounded {
  border-radius: 1.5em;
  background-color: var(--fill-white);
  border: 1px solid var(--fill-primary-medium);
  padding: 1em 1.5em;
  text-transform: uppercase;
  color: var(--fill-primary-medium);
  text-align: center;
}

.btn--small-border-radius {
  border-radius: 0.5em;
  padding: 8px 20px;
}

.btn--faded {
  color: var(--fill-grey-medium) !important;
  border-color: var(--fill-grey-medium) !important;
}

.btn--medium {
  padding: 10px 16px;
}

.btn--uppercase {
  text-transform: uppercase;
}

.btn--text {
  color: var(--fill-primary-blue);

  &:hover {
    color: var(--fill-primary-btn-hover);
  }
}

.btn--right {
  text-align: right;
  padding-left: 0px;
  background: transparent;
}

.btn--add {
  border: 1px dotted var(--fill-grey-light);
  border-radius: 50%;
  padding: 12px 15px;
}

.btn--blue-bg {
  background-color: var(--fill-primary-blue);
  text-align: center;
  color: var(--fill-white);
  padding: 10px 16px;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  &:hover,
  &:focus {
    &:not(:disabled),
    &:not(.btn--disabled) {
      background-color: var(--fill-primary-btn-hover) !important;
    }
  }
}

.btn--ghost {
  padding: 10px 12px;
  background: white;
  border-radius: 4px;
  text-transform: capitalize;
}

.btn--filled {
  padding: 10px 12px;
  background: white;
  border-radius: 4px;
  text-transform: capitalize;
}

.btn--blue {
  border: 1px solid var(--fill-primary-blue);
  color: var(--fill-primary-blue);
  line-height: 20px;

  &:hover {
    color: var(--fill-primary-btn-hover);
  }
}

.btn--blue-inverted {
  background-color: var(--fill-primary-blue);
  color: white;
  border-radius: 0.5em;
  font-size: 12px;

  &:hover {
    background-color: var(--fill-primary-btn-hover);
  }
}

.btn--disabled {
  background-color: var(--fill-grey-light2);
  color: white;
  border-radius: 4px;
  font-size: 14px;
  cursor: default;
  line-height: 20px;
}

.btn--filter,
.btn--img {
  background: white;
  padding: 9px 12px;
  border-radius: 8px;
  border: 1px solid var(--fill-grey-light2);
  max-height: 40px;

  > img,
  > .img {
    height: 20px;
    vertical-align: sub;
  }

  &.btn--disabled {
    color: var(--fill-grey-medium);
  }
}

@mixin btnStyles {
  text-align: center;
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}

.btn--primary-blue {
  @include btnStyles();
  background: var(--fill-primary-blue);
  color: var(--fill-white);
  &:hover {
    &:not(:disabled) {
      background-color: var(--fill-primary-btn-hover);
    }
  }

  &.btn--disabled,
  &:disabled {
    background: var(--fill-grey-light2);
  }

  &.btn--sm {
    padding: 0 6px;
    text-transform: none;
    font-size: 12px;
  }
}

// Eg: Edit and replace measuremnet sheet
.btn--plain-blue {
  border: 0;
  user-select: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  color: var(--fill-primary-blue);
  font-weight: var(--f-w-semi-bold);
  line-height: 1.43;
  border-radius: 4px;

  &:hover {
    color: var(--fill-primary-btn-hover);
  }
}

.btn--black-ghost {
  border: 1px solid var(--fill-grey-light2);
  color: var(--fill-locofast-black);
  padding: 10px 16px;
  line-height: 18px;
  border-radius: 4px;
  font-weight: bold;
}

.btn--blue-ghost {
  @include btnStyles();
  border: 1px solid var(--fill-primary-blue);
  color: var(--fill-primary-blue);
}

.btn--red {
  padding: 8px 20px;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: var(--fill-secondary-dark);
  color: var(--fill-white);
  border: 1px solid var(--fill-secondary-dark);
}

.btn--red-invert {
  padding: 8px 20px;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: var(--fill-white);
  color: var(--fill-secondary-dark);
  border: 1px solid var(--fill-secondary-dark);
}

.btn--red-text {
  color: var(--fill-secondary-dark);
}

.btn--primary-text {
  color: var(--fill-primary-blue);
  font-weight: bold;
  border-radius: 4px;

  &:hover,
  &:focus {
    color: var(--fill-primary-btn-hover) !important;
  }
}

.btn--primary {
  color: var(--fill-white);
  background-color: var(--fill-primary-medium);
  padding: 12px 24px;
  border-radius: 5px;
}

.btn--grid-2 {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
}

.btn--white-bg {
  color: #333340;
  padding: 7px 12px !important;
  border: solid 1px #d4d4d4 !important;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 1.43 !important;
  letter-spacing: normal;
  font-weight: bold;

  &:hover {
    background-color: #ffffff !important;
  }
}

.btn--quote-blue-bg {
  padding: 8px 12px 8px 12px !important;
  background-color: var(--fill-primary-blue);
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.43;
  font-weight: bold;
  color: var(--fill-white);

  &:hover {
    background-color: var(--fill-primary-btn-hover) !important;
  }
}

.btn--link {
  padding: 0;
  color: var(--fill-primary-blue);
  background: transparent;
}

.btn--shrink {
  padding: 0 !important; // shrink is for removing the padding; so important
  border: none;
  border-radius: 0;
}

.btn--blue-bg-outline {
  @include btnStyles();
  border: 1px solid var(--fill-primary-blue);
  background-color: white;
  color: var(--fill-primary-blue);
  text-transform: none;

  &:hover,
  &:focus {
    &:not(:disabled),
    &:not(.btn--disabled) {
      color: var(--fill-primary-btn-hover) !important;
      border-color: var(--fill-primary-btn-hover) !important;
    }
  }

  &.btn--disabled {
    color: var(--fill-grey-light2) !important;
    border-color: var(--fill-grey-light2) !important;
  }
}

.btn--primary-with-bg {
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 24px;
  background: var(--fill-blue-light3);
  color: var(--fill-primary-blue);
}
