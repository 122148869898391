.manage-team-modal {
  // min-height: 524px;
  // max-height: 524px;
  width: 500px;
  display: flex;
  flex-direction: column;

  .tooltip {
    background: var( --fill-grey-dark);
    color: var(--fill-white);
    padding: 4px 8px;
    border-radius: 8px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      border: 10px solid var(--fill-grey-dark);
      border-color: transparent;
      border-top-color: var(--fill-grey-dark);
    }
  }

  .empty-state {
    height: 280px;
    background: rgba(225, 225, 234, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      color: var(--fill-grey-medium);
      padding-top: 16px;
    }
  }

  .no-results {
    font-weight: 600;
    font-size: 18px;
    color: var(--fill-grey-medium);
  }

  &__content {
    padding: 0 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 462px;
  }

  &__search {
    input {
      --padding-left: 24px;
      padding: 16px 8px;
    }

    .input-grp label {
      margin-bottom: 16px;
      text-transform: initial;
    }
  }

  &__users {


    &-count {
      padding: 16px 0;
      margin-top: 24px;
      color: var(--fill-grey-dark);
      font-size: 12px;

      .count {
        background: rgba(225, 225, 234, 0.2);
        padding: 4px 8px;
        border-radius: 50%;
        font-size: 12px;
        margin-left: 8px;
      }
    }

    &-list {
      flex: 1;
      margin-top: 0;
      overflow: auto;
      margin-right: -10px;
      padding-right: 10px;
    }

    &-search {
      position: absolute;
      background-color: var(--fill-white);
      z-index: 2;
      width: 452px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      max-height: 340px;
      height: auto;
      min-height: 72px;

      .manage-team-modal__users-item {
        border-radius: 0;
      }

      .empty-list-container {
        height: 72px;

        .content {
          margin-top: 0;
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 12px 8px;

      .profile-avatar {
        box-shadow: none;
      }

      &,
      * {
        transition: background-color 100ms ease-out, border-color 100ms ease-out;
      }

      &.removed {
        background-color: var(--fill-red-light2);
        border: 1px solid var(--fill-orangered);

        &:hover {
          background-color: var(--fill-red-light2);
        }
      }

      &.added {
        background-color: var(--fill-green-secondary);
        border: 1px solid var(--fill-success);

        &:hover {
          background-color: var(--fill-green-secondary);
        }
      }

      .user-name {
        padding-left: 16px;
        color: var(--fill-grey-dark);
      }

      .user-role {
        color: var(--fill-grey-medium);
        font-size: 12px;
        font-weight: 600;
        background-color: rgba(225, 225, 234, 0.2);
        padding: 6px 8px;
        border-radius: 16px;
        line-height: 16px;
      }
      .profile-avatar {
        border: none;
        min-height: 40px;
        min-width: 40px;
        max-height: 40px;
        max-width: 40px;

        > .initials {
          font-weight: 600;
        }
      }

      svg path {
        fill: red;
      }

      &.selected {
        background-color: var(--fill-blue-light3);
        text-shadow: none;
        font-weight: 600;

        .profile-avatar {
          background-color: var(--fill-purple-light3) !important;

          > .initials {
            color: white;
          }
        }

        .user-name {
          color: var(--fill-primary-blue);
        }
      }

      &:hover {
        background-color: var(--fill-grey-light7);

        .btn {
          background-color: var(--fill-light-gray);

          img {
            filter: brightness(0) invert(1);
          }
        }
      }

      &:focus {
        border: 1px solid var(--fill-primary-blue);
      }

      .btn {
        height: 24px;
        width: 24px;
        background: transparent;
        border-radius: 50%;
        margin-left: 16px;

        img {
          transform: scale(0.6);
          position: relative;
          left: -6px;
          top: -1px;

        }

        &.undo {
          img {
            transform: scale(1);
          }
        }
      }
    }
  }

  &__footer {
    padding: 12px 24px;
    border-top: 1px solid var(--fill-grey-light3);
    display: flex;
    justify-content: space-between;

    .changes {
      padding-right: 24px;
      font-weight: 600;
      .grey {
        color: var(--fill-grey-medium);
        font-weight: normal;
      }
    }
  }
}
