.org-row-details-tabs {
  padding: 24px 0 16px;

  > .tabs-nav {
    border-bottom: none;
    margin: 0 8px;

    & > .tabs-nav__item {
      font-weight: normal;

      &:not(.tabs-nav__item--active) {
        color: var(--fill-locofast-black);
      }
    }
  }
}
