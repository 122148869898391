.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 28px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 13px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control label {
  user-select: none;
}

.control_indicator {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 2px solid #787878;
}

.control_indicator {
  border-radius: 2px;
  border: 1px solid var(--fill-grey-light2);
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  border: 1px solid var(--fill-primary-blue);
}

.control input:checked ~ .control_indicator {
  // background: #794f85;
  // border: 1px solid #794f85;
  background-color: var(--fill-primary-blue);
  border: 1px solid var(--fill-primary-blue);
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  // background: #794f85;
  background-color: var(--fill-primary-blue);
  border: 1px solid var(--fill-primary-blue);
}

.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
  border: 1px solid var(--fill-grey-light);
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 6px;
  top: 1px;
  width: 4px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox--light {
  .control_indicator {
    border: 1px solid #bebebe;
  }
}

.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
