.addLfUser {
  .label__class {
    color: var(--fill-grey-dark);
    display: inline-block;
    line-height: 16px;
    margin-bottom: 6px;
  }

  .inputField {
    padding: 24px 0 0;
    flex: 1;
  }

  .fields-two .inputField {
    padding-top: 0;
  }

  input {
    color: var(--fill-locofast-black);
    width: 100%;
  }

  // Hides number controls
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[data-invalid] {
    border: 1px solid var(--fill-error);
  }

  .formContent {
    padding: 16px 32px 32px 32px;
    position: relative;
    width: 560px;
    min-height: 440px;

    .select-input-transparent {
      border: none;
      background-color: transparent;
      max-width: 100%;
      width: 100%;
      color: var(--fill-locofast-black);
    }

    .maxActiveQuery {
      display: flex;
      align-items: center;

      input {
        width: 56px;
        text-align: center;
      }

      button {
        width: 32px;
        height: 32px;
        background-color: var(--fill-grey-light5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
          margin-right: 8px;
        }
        &:last-child {
          margin-left: 8px;
        }
        span {
          font-size: 24px;
          color: var(--fill-grey-medium);
        }
      }
    }
  }

  .modal__footer button{
    width: 88px;
  }
}
.select-pane {
  top: 42px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 1, 38, 0.5);
  background-color: var(--fill-white);
  position: absolute;
  width: 100%;
  z-index: 10000;
  transform: translate(-12px,1px);
}
.collection-collab__label_left_aigned {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
}