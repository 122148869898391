.org-users {
  &-row {
    align-items: center;
    padding: 12px 20px;
    margin-top: 24px;

    .cta-add {
      grid-gap: 16px;
    }
  }

  &-item {
    display: grid;
    transition: all 100ms;

    .user-profile {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 16px;
      align-items: center;
    }

    .cta-on-hover {
      opacity: 0;
      transition: all 100ms;
    }

    .btn {
      background-color: transparent;
    }

    .user-status {
      &.active {
        color: var(--fill-success);
      }
      &.inactive {
        color: var(--fill-orangered);
      }
      &.invite-pending {
        color: var(--fill-grey-medium2);
      }
    }

    &:hover {
      background-color: var(--fill-blue-100);

      .cta-on-hover {
        opacity: 1;
      }
    }
  }
}
