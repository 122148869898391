.empty-list-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 96px;
    height: 96px;
    overflow: hidden;
    margin: 0 auto;
  }

  .content {
    text-align: center;
    font-size: 14px;
    margin-top: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333340;

    .heading {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      border-bottom: none;
      padding: 0;
      color: #000126;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .description {
      margin-top: 4px;
      color: var(--fill-grey-dark);
    }

    .cta {
      display: flex;
      margin-top: 24px;
      align-items: center;
      justify-content: center;

      .btn {
        min-height: 36px;

        & + .btn {
          margin-left: 12px;
        }
      }
    }
  }

  &.orientation-horizontal {
    flex-direction: row;

    .img-container {
      margin: 0;
      margin-right: 16px;
    }

    .content {
      text-align: left;
      align-self: flex-end;
      margin-top: 0;
    }
  }

  &.position {
    &-center .img-container {
      align-items: center;
    }
    &-bottom .img-container {
      align-items: flex-end;
    }
  }

  &.curved {
    .img-container {
      border-radius: 50%;
    }
  }
}
