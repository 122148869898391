.add-customer-modal {
  width: 880px;
  height: 750px;
  display: flex;
  flex-direction: column;

  .progress-steps {
    flex: 1;

    .bar_top {
      padding: 4px 24px;
      border-bottom: 1px solid var(--fill-grey-light3);
    }

    .bar__content {
      height: 100%;
      padding: 40px 32px;
    }
  }

  .customer-form {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
    column-gap: 32px;

    .formik-field {
      & > label {
        padding-top: 0;
        font-size: 12px;
        line-height: 16px;
      }

      & + .formik-field {
        margin-top: 24px;
      }

      &.additional-details textarea {
        height: 174px;
      }
    }

    .col {
      flex: 1;
    }
  }

  .modal-footer {
    .cta-submit {
      min-width: unset;
      grid-gap: 4px;

      .loco-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.choose-type-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .option-button {
    column-gap: 24px;
    min-width: 216px;
    display: flex;
    text-align: left;
    border: 1px solid var(--fill-grey-light5);
    box-sizing: border-box;
    border-radius: 8px;

    &,
    * {
      transition: all 100ms;
    }

    &__img {
      position: relative;
      background: rgba(225, 225, 234, 0.2);
      border-radius: 8px;
      padding: 12px;
    }

    &__text {
      color: var(--fill-grey-dark);
      font-size: 16px;
      line-height: 22px;
      flex: 1;
    }

    &__success {
      position: absolute;
      top: 50%;
      right: 0;
      opacity: 0;
      transform: translate(50%, -50%);
    }

    &--disabled {
      cursor: default;
      opacity: 0.8;
    }

    &--selected:not(&--disabled) {
      background-color: var(--fill-blue-light3);

      .option-button__img {
        background-color: transparent;
      }

      .option-button__text {
        color: var(--fill-primary-blue);
      }

      .option-button__success {
        opacity: 1;
      }
    }

    & + .option-button {
      margin-left: 56px;
    }

    &:not(&--disabled):hover {
      background: var(--fill-blue-light5);

      .option-button__img {
        background: rgba(225, 225, 234, 0.2);
      }
    }
  }

  &__option-list {
    display: flex;
  }
}
