@import '../../../../../assets/scss/mixins';

.assigned-users__avatar {
  border-radius: 26px;
  padding: 4px;
  min-height: 40px;
  max-height: 40px;
  cursor: pointer;

  .profile-avatar {
    @include fix-size(32px);

    border: 1px solid var(--fill-white);
    position: relative;
    display: inline-block;

    >.img{
      @include fix-size(32px);
    }

    .initials {
      line-height: 30px;
      font-size: 12px;
    }

    & + .profile-avatar {
      margin-left: -12px;
    }

    &:last-of-type {
      margin-right: 4px;
    }

    &:nth-child(1) {
      z-index: 1;
    }
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(3) {
      z-index: 3;
    }
    &:nth-child(4) {
      z-index: 4;
    }
    &:nth-child(5) {
      z-index: 5;
    }
  }

  img.users {
    height: 20px;
    width: 20px;
    margin: 6px;
  }
}
