@import "./layout.scss";
@import "./_checkbox.scss";
//@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Volkhov&display=swap");

:root {
  --f-w-semi-bold: 600;
  --f-w-bold: bold;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

/**
 * Firefox specific rule
 */
@-moz-document url-prefix() {
  body {
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
  }
  // .f-w-bold {
  //   font-weight: 600;
  // }
}

:root {
  --f-w-semi-bold: 600;
  --f-w-bold: bold;
}

// :global {
  // @media screen and (max-width: 1365px) {
  // .lf-application-container {
  //   max-height: 667px;
  //   max-width: 1366px;
  // }
  // }
  .lf-application-container {
    height: 100%;
    overflow: hidden;
  }
  /* Portrait and Landscape */
  @media only screen and (min-device-width: 0px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .lf-application-container {
      min-height: 768px;
      min-width: 1366px;
      overflow: hidden;
      height: 100%;
    }
  }

  // == This thing I added for HZ & VR scroll in notification container ==
  @media only screen and (max-width: 900px) {
    .lf-application-container {
      min-width: max-content;
      min-height: 100vh;
    }
  }

  a[disabled] {
    pointer-events: none !important;
    cursor: default;
  }

  .lf-percent-input {
    &:focus-within {
      outline: -webkit-focus-ring-color auto 5px;
    }
    display: inline-flex;
    align-items: center;
    padding: 0 !important;
    position: relative;
    border-right: 1px solid var(--fill-grey-light2);

    input {
      height: 100%;
      width: 80%;
      padding-right: 0 !important;
      &:disabled {
        color: var(--fill-locofast-black);
        background-color: transparent !important;
      }
    }
    input:focus {
      outline: none;
    }
    &::after {
      content: "%";
      position: absolute;
      right: 1em;
    }
  }
  .underline {
    text-decoration: underline;
  }

  // React-data-grid global styles
  // https://github.com/adazzle/react-data-grid/issues/736#issuecomment-297844382
  .react-grid-Main {
    height: 100%;
  }

  .react-grid-Container {
    height: 95%;
  }

  .react-grid-Grid {
    min-height: 100% !important;
  }

  .react-grid-Canvas {
    height: 100% !important;
  }

  .react-tiny-popover-container {
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(0, 1, 38, 0.4);
    background-color: var(--fill-white);
    border: 1px solid var(--fill-grey-light2);
    z-index: 99999;
  }

  * {
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;
  }
  .placeholder {
    color: var(--fill-grey-medium);
  }

  .downshift,
  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="email"],
  input[type="number"],
  textarea {
    padding: 13px 12px;
    border-radius: 4px;
    border: 1px solid var(--fill-grey-light3);

    ::placeholder {
      color: #bfbfbf;
    }

    :disabled {
      color: var(--fill-grey-medium);
    }

    &:focus {
      border-color: var(--fill-primary-blue);
    }
  }

  .downshift {
    user-select: none;
    position: relative;
    cursor: pointer;
    &[aria-expanded="true"] {
      border-color: var(--fill-primary-blue);
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: inherit;
    }
  }

  #datepicker {
    & > div {
      width: 100%;
    }
  }

  .react-datepicker__input-container {
    width: 100%;

    .react-datepicker__close-icon::after {
      background-color: transparent !important;
      color: var(--fill-grey-dark);
    }
  }

  .custom-datepicker {
    background-image: url(../images/action/ic-calendar-today.svg);
    background-repeat: no-repeat;
    background-size: 24px 24px;
    min-height: 24px;
    background-position: right center;
    background-position-x: 97%;
    width: 100%;
    cursor: pointer;
  }

  .custom-datepicker-nobg {
    background-image: none !important;
  }

  .custom-datepicker-hidden {
    // background-image: url(../images/action/ic-calendar-today.svg);
    background-repeat: no-repeat;
    background-size: 24px 24px;
    min-height: 24px;
    background-position: right center;
    background-position-x: 97%;
    width: 100%;
    cursor: pointer;
    border: 1px solid #fff !important;
    padding: 0px !important;

    &::placeholder {
      color: var(--fill-secondary-medium);
      font-weight: 600;
    }
  }

  .custom-datepicker:disabled {
    background-image: none;
    background-color: #ededed;
    cursor: default;
  }

  .custom-datepicker-popper {
    // border: 1px solid #fff !important;
  }

  // .custom-datepicker-popper-create-order {
  //   margin-right:
  // }

  // .btn {
  //     border: 0;
  //     user-select: none;
  //     cursor: pointer;
  //     padding: 8px 20px;
  //     text-transform: uppercase;
  //     border-radius: 4px;
  //     background-color: transparent;
  // }

  select {
    padding: 11px 12px;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
  }

  [contenteditable="true"].single-line {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
  }

  [contenteditable="true"].single-line br {
    display: none;
  }

  [contenteditable="true"].single-line * {
    display: inline;
    white-space: nowrap;
  }

  .loco__radio {
    label {
      flex-grow: 1;
      margin-bottom: 0;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      // padding-left: 28px;
      cursor: pointer;
      line-height: 19px;
      display: inline-block;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid var(--fill-grey-light2);
      border-radius: 100%;
      background: #fff;
    }

    [type="radio"]:checked + label:before {
      border: 1px solid var(--fill-primary-blue);
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 12px;
      height: 12px;
      background: var(--fill-primary-blue);
      position: absolute;
      top: 4px;
      right: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    [type="radio"]:disabled {
      & + label {
        color: var(--fill-grey-medium);
        cursor: default;
      }

      &:checked {
        & + label {
          &:before {
            border-color: var(--fill-grey-medium);
          }

          &:after {
            background: var(--fill-grey-medium3);
          }
        }
      }
    }

    display: flex;
    justify-content: space-between;
    // flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    padding: 14px 16px;
    border: 1px solid var(--fill-grey-light2);

    span {
      margin: 0;
      line-height: 1.43;
      letter-spacing: 0.2px;
      font-size: 14px;
      font-weight: 600;
    }

    input {
      margin: 0;
    }

    // FIXME: most of the loco__radio styles are declared above, I am containing redundant code, please fix.
    &.loco__radio--left-align {
      &.loco__radio--light {
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          border-width: 1px;
        }
      }

      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 30px;
        line-height: 20px;
        display: inline-block;
      }

      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 100%;
      }

      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }

  .loco__radio_tabs {
    label {
      flex-grow: 1;
      margin-bottom: 0;
      padding: 6px 12px;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      // padding-left: 28px;
      cursor: pointer;
      line-height: 19px;
      display: inline-block;
    }

    [type="radio"].checked + label {
      background: var(--fill-blue-light);
      border-radius: 8px;
      color: var(--fill-primary-blue);
    }

    [type="radio"].checked,
    [type="radio"]:not(.checked) {
      position: absolute;
      left: -9999px;
    }

    [type="radio"].checked + label,
    [type="radio"]:not(.checked) + label {
      position: relative;
      // padding-left: 28px;
      cursor: pointer;
      line-height: 19px;
      display: inline-block;
    }

    [type="radio"].checked + label {
      background: var(--fill-blue-light);
      border-radius: 8px;
      color: var(--fill-primary-blue);
    }
    span {
      margin: 0;
      line-height: 1.43;
      letter-spacing: 0.2px;
      font-size: 14px;
      font-weight: 600;
    }

    input {
      margin: 0;
    }
  }

  label {
    user-select: none;
    cursor: pointer;
  }

  .transform-center {
    transform: translate(-50%, -50%);
    // position: absolute;
    // top: 50%;
    // left: 50%;
  }

  .custom-tooltip {
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: var(--fill-white) !important;
    padding: 4px 10px;
    border: 1px solid var(--fill-grey-medium);
    background: var(--fill-locofast-black);
    border-radius: 4px;
    min-height: 24px;
    &.show {
      opacity: 1;
    }
  }

  .dark-tooltip, .v2-tooltip {
    font-weight: 600;
    font-size: 12px !important;
    text-align: center !important;
    color: var(--fill-white) !important;
    padding: 6px 10px;
    border: 1px solid var(--fill-grey-medium);
    background-color: var(--fill-locofast-black) !important;
    border-radius: 4px;
    min-height: 24px;
    max-width: 200px;
    margin-left: 32px !important;
    text-align: left !important;
    margin-top: 4px;
    line-height: 16px;

    &.show {
      opacity: 1;
    }
  }

  .v2-tooltip {
    margin-left: -32px !important;
    padding: 4px 8px;
    border-radius: 8px;
  }

  .dark-tooltip:after {
    display: none;
  }

  .over-modal {
    z-index: 10000;
    opacity: 1 !important;
    margin-top: 0;

    &::after {
      border: 0 !important;
    }
  }

  .custom-input[disabled] {
    background-color: #ededed;
    cursor: default;
  }
  .custom-tooltip:after {
    display: none;
  }

  .white-tooltip {
    background-color: var(--fill-white) !important;
    color: var(--fill-locofast-black);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    max-width: 250px;
    opacity: 1;
    padding: 12px;
    border: 1px solid var(--fill-grey-light3);

    .text {
      font-weight: 600;
      color: var(--fill-locofast-black);
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 4px;
    }

    .subtext {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: var(--fill-grey-dark);
    }

    &.show {
      opacity: 1;
    }
  }

  .white-tooltip:after {
    display: none;
  }

  html,
  body,
  #root,
  #root > div {
    height: 100vh;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    color: var(--fill-locofast-black);
  }

  // .ps__rail-x {
  //   z-index: 999999;
  // }
  // .ps__rail-y {
  //   z-index: 999999;
  // }

  a {
    color: inherit;
    text-decoration: none;
  }

  i {
    cursor: pointer;
  }

  .hide {
    display: none !important;
  }
  .hide-visibility {
    visibility: hidden !important;
  }

  .flex {
    display: flex;
  }

  .flex.center {
    align-items: center;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .flex-grow {
    flex: 1;
  }

  .flex-v-center {
    align-self: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .items-center {
    align-items: center;
  }

  .grid {
    display: grid;
  }

  .i-grid {
    display: inline-grid;
  }

  .f-sz-xs {
    font-size: 9px;
  }

  .f-sz-sm {
    font-size: 12px;
  }

  .f-sz-m {
    font-size: 13px;
  }

  .f-sz-m-16 {
    font-size: 16px;
  }

  .f-sz-14 {
    font-size: 14px;
  }

  .f-sz-l {
    font-size: 15px;
  }

  .f-sz-xl {
    font-size: 18px;
  }

  .f-sz-xxl {
    font-size: 24px;
  }

  .f-w-bold {
    font-weight: 500;
  }

  .f-w-semi-bold {
    font-weight: 400;
    // line-height: 1.33;
  }

  .title__heading {
    font-weight: 600;
  }

  .f-color-faded {
    color: var(--fill-grey-medium);
  }

  .f-color-faded-2 {
    color: var(--fill-light-gray);
  }

  .f-color-black {
    color: var(--fill-locofast-black);
  }

  .f-color-blue {
    color: #004d99;
  }

  .f-color-success {
    color: var(--fill-green-2);
  }

  .f-color-error {
    color: var(--fill-orangered);
  }

  .card {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--fill-grey-light2);

    .card__section {
      flex-grow: 1;
      box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
    }

    // Remove box-shadow bottom border from the last child
    &:last-child {
      box-shadow: none;
    }
  }
  .reduxForm__overflow.reduxForm__overflow--enabled {
    overflow: visible !important;
  }
  .reduxForm__overflow.reduxForm__overflow--disabled {
    overflow: hidden !important;
  }

  .pop-over__item {
    overflow: hidden;
    width: 100%;
    min-height: 48px;

    align-items: center;
    line-height: 1.43;
    padding: 0 18px;
    user-select: none;
    cursor: pointer;

    * {
      flex-grow: 1;
    }

    img {
      width: 20px;
      max-width: 20px;
      height: 20px;
    }

    span {
      text-align: left;
      padding: 0 10px;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .switch.switch--custom {
    > .switch-toggle {
      height: 14px;
      width: 38px;
      pointer-events: none;
    }

    > .switch-toggle--on::after,
    .switch-toggle--off::after {
      height: 22px !important;
      width: 22px !important;
      top: -5px !important;
    }
  }
  // @include build-grid();
// }

// === dez styels are for dropdown in Sampling notfication
.notification_sampling_menu {
  text-align: left;
  font-size: 12px;
  color: var(--fill-grey-medium);
  font-weight: 300;
  padding: 10px 0px;
}
.notification_sampling_class {
  font-weight: 300;
}
.notification_sampling_arrow {
  top: 5px;
}
.notification_sampling_control {
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 0px;

  width: 250px;
}

.notification_sampling_placeholder {
  padding-right: 10px;
  font-weight: 600;
}

.sampling__dropdown_menu {
  padding: 0px 20px 4px 0px !important;
  background-color: #fff;
}
.sampling__dropdown_menu_grey {
  // padding: 0px 20px 4px 0px !important;
  background-color: #f5f5f5;
  // height: "40px";
  // padding: 12px 0px;
}

.sampling__dropdown_arrow_search {
  top: 18px;
}

.sampling__dropdown_menu_white {
  padding: 0px 20px 4px 0px !important;
  background-color: #fff;
  min-width: 120px;
}

.sampling__dropdown_menu_search {
  // padding: 0px 20px 4px 0px !important;
  background-color: #fff;
  min-width: 120px;
}

.sampling__dropdown_arrow {
  top: 5px;
}

// ********************
.label__class {
  font-size: 12px;
  color: var(--fill-grey-medium);
}

.value__class {
  font-size: 14px;
  font-weight: 600;
  color: var(--fill-locofast-black);
  line-height: normal;
}

// ******************* borderbottom not for first item
.borderBottom__enhancer {
  &:not(:first-child) {
    border-bottom: "1px solid var(--fill-grey-light)";
  }
}

// .message__pick:before {
//   content: ' ';
//     position: absolute;
//     width: 0;
//     height: 0;
//     left: auto;
//     right: 0px;
//     top: 20;
//     bottom: auto;
//     border: 22px solid;
//     border-color:  transparent transparent blue transparent;
// }

// input error
.inputerror:focus {
  outline-color: red;
}

// search dropdown section
.search-dropdown {
  background: #fff;
  position: absolute;
  top: 42px;
  width: 100%;
  box-shadow: 0 0 20px #ddd;
  border-radius: 0 0 7px 7px;

  .search-dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0.5rem;
    }
  }

  .heading {
    font-size: 0.7rem;
    background: #f5f4f4;
    padding: 0.7rem;
    text-transform: uppercase;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .heading-link {
    color: #489ee2;
  }
}

.clickable {
  cursor: pointer;
}

.search_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  .search-input {
    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }
}

.upload-text {
  margin-left: 8px;
}

.tag-pill {
  width: auto;
  padding: 4px 6px;
  font-size: 10px;
  overflow: hidden;
  line-height: 16px;
  border-radius: 35px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--fill-locofast-black);
  background-color: var(--fill-grey-light3);
}

.selected {
  text-shadow: 0px 0px 1px var(--fill-grey-dark);
}

.current_tab {
  background: var(--fill-grey-light4);
}

.samplingrequest__dropdown_menu_white {
  border: 1px solid var(--fill-grey-light);
  border-radius: 2px;
  padding: 15px;
}

.samplingrequest__dropdown_menu {
  padding: 0;
  left: 0px;

  .Dropdown-option {
    &:hover {
      background: var(--fill-grey-light5);
    }
    padding: 15px !important;
  }
}

.samplingrequest__dropdown_arrow {
  top: 5px;
}

sup {
  top: -0.2em;
}

.error {
  color: var(--fill-error);
}

.capitalize {
  text-transform: capitalize;
}

.fly-right {
  float: right;
}

.fly-left {
  float: left;
}

.tab__filters {
  user-select: none;
  position: relative;
  cursor: pointer;
  border: solid 1px var(--fill-grey-light3);
  border-radius: 8px;
  padding: 6px 12px;
  height: 48px;
  width: 178px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  input {
    border: none;
    background-color: transparent;
    font-weight: 600;
    color: var(--fill-locofast-black);
    cursor: pointer;
    width: 90%;
  }
}

.reset_filters {
  flex-grow: 2;
  text-align: right;

  span {
    padding: 10px 12px;
    border: 1px solid var(--fill-grey-light3);
    border-radius: 8px;
    color: var(--fill-primary-blue);
  }
}

.qoute-text {
  font-size: 12px;
  padding-right: 10px;
  margin-bottom: 0px;
  margin-top: 5px;
  color: var(--fill-grey-medium3);
}
.split_modal_view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
}

.blue_container {
  background: var(--fill-blue-light2);
}

.downshift_radio {
  label {
    padding: 0 !important;
    padding-left: 30px !important;
  }

  .loco__radio {
    border: 0;
    padding: 10px 14px 10px 0;
  }

  .loco__radio.loco__radio--left-align [type="radio"]:checked + label:before,
  .loco__radio.loco__radio--left-align [type="radio"]:not(:checked) + label:before {
    border: 1px solid var(--fill-grey-light);
  }

  .loco__radio [type="radio"]:checked + label:before {
    border: 1px solid var(--fill-primary-blue) !important;
  }

  .loco__radio.loco__radio--left-align [type="radio"]:checked + label:after,
  .loco__radio.loco__radio--left-align [type="radio"]:not(:checked) + label:after {
    background: var(--fill-primary-blue);
  }
}

.table {
  .table-header {
    padding: 6px 64px;
  }
  .table-row {
    border-radius: 8px;
    padding: 16px 20px;
    margin: 0 44px;

    &:hover {
      background-color: var(--fill-grey-light5);
    }
  }
}

.active_loco__radio {
  border: 1px solid var(--fill-primary-medium);
  background-color: var(--fill-primary-lighter);
}

.active_loco__radio [type="radio"]:checked + label:before {
  border: 1px solid var(--fill-primary-medium) !important;
}

.active_loco__radio [type="radio"]:checked + label:after,
.loco__radio [type="radio"]:not(:checked) + label:after {
  background: var(--fill-primary-medium);
}

//scroll custom css
//collection file
// .custom-scroll-container {
//height: calc(100vh - 215px);
// overflow: auto;
// height: 80vh;
// margin-bottom: 20vh;
// }

// //sample tracker file
// .custom-scroll-sample {
//   height: calc(100vh - 56px);
//   overflow: auto;
// }

//design grid file
// .custom-scroll-grid {
//   height: calc(100vh - 170px);
//   overflow: auto;
//   margin-bottom: 1rem;
// }

///sticky class custom
.sticky-scroll {
  position: sticky;
  top: 0;
  z-index: 2;
}

// .absolute-position {
//   position: absolute;
//   top: 55px;
// }

// custom-datagrid-table
.custom-datagrid-table .react-grid-Main {
  padding: 10px;
}

// .brandtable-scroll-vh {
//   overflow: scroll;
//   height: calc(100vh - 120px);
// }

// when there are no results
.empty-filter-result {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 25px;
  min-height: 50vh;

  .heading {
    font-size: 16px;
    font-weight: bold;
    color: var(--fill-locofast-black);
    padding: 0;
    border: none;
  }

  .sub-heading {
    color: var(--fill-grey-medium);
    font-size: 14px;
  }
}

.scroll-auto {
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
}

.grey-overlay {
  opacity: 0.2;
}

.link-blue {
  color: var(--fill-primary-blue);
}

//sampling css.....
.text-btn-link {
  background-color: none;
  border-radius: 0;
  box-shadow: none;
  color: var(--fill-primary-blue);
  padding: 0;
  font-weight: 600;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-lines-2 {
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}

.link-hover {
  cursor: pointer;

  &:hover {
    color: var(--fill-primary-blue);
  }
}

.cell-v2 {
  > *:first-child {
    font-size: 14px;
    line-height: 20px;

    &.header {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }

  > *:not(:first-child) {
    font-size: 12px;
    line-height: 16px;
    color: var(--fill-grey-medium);
    margin-top: 4px;
  }
}

.cell-info {
  > .label {
    color: var(--fill-grey-dark);
    font-size: 12px;
    line-height: 16px;
  }

  > .info {
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }

  & + .cell-info {
    margin-left: 16px;
  }
}

.rot-180 {
  transform: rotate(180deg);
}

.t-align-right {
  text-align: right;
}

.tooltip-container {
  position: relative;
  .tooltip {
    opacity: 0;
    position: absolute;
    margin: 0 !important;
    left: -9999px;
    top: -9999px;
    z-index: 100;
  }

  &:hover {
    > .tooltip {
      --padding: 4px;

      opacity: 1;

      &.dark-tooltip {
        max-width: unset;
        white-space: nowrap;
      }

      &--h {
        &-center {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &--v {
        &-bottom {
          top: calc(100% + var(--padding));
        }

        &-top {
          bottom: calc(100% + var(--padding));
          top: unset;
        }
      }
    }
  }
}

.info-note {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 12px;
  padding: 12px 16px 12px 12px;
  background: var(--fill-yellow-light);
  border: 1px solid rgba(0, 1, 38, 0.1);
  border-radius: 4px;

  .loco-icon {
    width: initial;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  .text {
    color: var(--fill-grey-dark);
  }
}

.faded_modal_bg {
  .modal__container {
    background-color: rgba(0, 1, 38, 0.1);
  }
}