.first-login {
  margin: auto;
}

.first-login__header {
  align-items: center;
  margin-bottom: 3em;
}

.first-login__header > span[role="img"] {
  font-size: 72px;
  margin-bottom: 0.5em;
}

.first-login__title {
  margin-bottom: 1rem;
}

.first-login__subtitle {
  text-align: center;
  color: var(--fill-grey-dark);
}
