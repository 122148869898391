.invite-users__multi-select {
    border-radius: 2px;
    border: 1px solid var(--fill-grey-light);
    padding-top: 1em;
}

.invite-users__item {
    // align-items: center
    padding: 1em 1em;
}

.invite-users__del {
    // align-self: flex-end;
    margin-left: auto;
    padding-right: 1em;
}

.inv-users__content {
    margin-bottom: 2em;
    color: var(--fill-grey-dark);
}