.error-boundary-container {
  .empty-list-container {
    .img-container {
      width: 180px;
      height: 35px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    > .content {
      margin-top: 40px;
    }

    .heading {
      font-size: 100px;
      font-weight: 800;
      line-height: normal;
    }

    .content {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }

    button {
      font-size: 14px;
    }
  }
}
