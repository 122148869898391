.flash-msg__container {
  position: absolute;
  transform: translate(-50%);
  left: 50%;
  top: 8em;
  z-index: 99999999;

  & > * {
    margin-bottom: 0.5rem;
  }
}

.flash-msg__container_action {
  width: 250px;
  height: auto;
  min-height: 44px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);
  background-color: var(--fill-locofast-black);
  position: fixed;
  margin-left: 80px;
  margin-bottom: 8px;
  bottom: -300px;
  left: 0px;
  z-index: 99999999;


  -webkit-animation: slide-in 0.5s forwards, slide-out 0.5s linear 5s;
  animation: slide-in 0.5s forwards, slide-out 0.5s linear 5s;
}

.status_icon img{
  position: absolute;
  top: 12px;
  left: 12px;
}

.cancel_icon img{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

// --fill-primary-dark: #004d99;
//   --fill-primary-medium: #0277bd;

.flash-msg__action_bar {
  width: 100%;
  height: 6px;
  background-color: var(--fill-primary-medium);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.flash-msg__action_body {
  width: auto;
  height: auto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--fill-white);
  padding: 14px 26px 14px 40px;
}

.flash-msg__action_type {
  text-transform: capitalize;
  padding: 0px 32px;
  padding-bottom: 16px;
}

@-webkit-keyframes slide-in {
  100% {
    bottom: 0;
  }
}

@keyframes slide-in {
  100% {
    bottom: 0;
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
    opacity: 0;
  }
}
