.reset-pass__content {
  & > * {
    flex-basis: 30%;
  }
}

.reset-password-modal {
  min-width: 500px;
  max-width: 500px;
  > .container {
    border: none;
    margin: 16px;
  }
}
