$sidebar-active-width: 10px;
$sidebar-inactive-color: #99c2f3;

.sidebar-custom__wrapper {
  display: flex;
  flex-direction: column;
  width: 72px;
  min-width: 72px;

  background-color: var(--fill-primary-blue);

  padding-top: 8px;

  text-align: center;
  z-index: 9;
}

.sidebar-custom__item {
  width: 100%;
  // height: 48px;
  max-width: 100%;
  max-height: 90px;
  min-height: 56px;

  padding: 12px 4px;

  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.sidebar-custom__link {
  .sidebar-custom__img {
    max-width: 24px;
    width: 24px;
    margin: auto;
    opacity: 0.5;
  }

  &.sidebar-link {
    &-notifications {
      .sidebar-custom__img {
        width: 36px;
        max-width: 36px;
      }
    }

    &-messages {
      .sidebar-custom__img {
        width: 24px;
        max-width: 24px;
      }
    }

    &-production {
      .sidebar-custom__img {
        width: 28px;
        height: 28px;
        max-width: 28px;
      }
    }

    &-orders {
      .sidebar-custom__img {
        width: 28px;
        max-width: 28px;
      }
    }
  }

  &.active {
    > .sidebar-custom__item {
      position: relative;
      &::before {
        content: " ";
        background: white;
        position: absolute;
        top: 14px;
        left: -$sidebar-active-width/2;
        width: $sidebar-active-width;
        height: 30px;
        border-radius: $sidebar-active-width;
      }
      .sidebar-custom__img {
        opacity: 1.0;
      }
    }
  }

  &:hover{
    .sidebar-custom__img {
      opacity: 1.0;
    }
  }
}

.sidebar-custom__item.sidebar-custom__btn {
  height: 56px;
  text-transform: capitalize;
}

.sidebar-custom__lower {
  margin-top: auto;
  .sidebar-custom__item {
    padding-bottom: 15px;
  }
}

.sidebar-custom__items {
  min-height: 100%;

  height: 100%;
  overflow: visible !important;
  position: relative;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar-custom__items {
    height: 100%;
  }
}
