
$black: #000000;
$white: #ffffff;

.pop-over__popup {
  position: relative;

  .bottom {
    bottom: 16px;
  }
}

.pop-over__popup-container {
  position: absolute;
  opacity: 1;
  transition: opacity 0.2s ease;
  display: flex;
  flex-direction: column;
  // Position over content
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  z-index: 500;
  padding: 16px;
  overflow: hidden;

  hr {
    width: 115%;
    margin-left: -20px;
    border-top: 1px solid var(--fill-grey-light3)
  }
}

.pop-over__popup-container--hide {
  visibility: hidden;
  opacity: 0;
}

.pop-over__popup-container button {
  margin: 10px;
}
